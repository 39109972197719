.footer {
  position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0.1rem 2rem;
    background: rgba(0, 0, 0, 0.9);
    color: #9b9d9e;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.2rem;
    display: flex;
    z-index: 9999;
  }

  .footer > span > a {
    color: #9b9d9e;
    text-decoration: none;
  }