
.glass-magnifier-grid-card > div {
    position: initial!important;
}

.glass-magnifier-grid-card > div > img {
    object-fit: contain;
    height: 175px;
}


.glass-magnifier-single-card > div {
    position: initial!important;
}

.glass-magnifier-single-card > div > img {
    object-fit: contain;
    height: 600px;
}