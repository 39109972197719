
.MuiPickerStaticWrapper-content {
    min-width: unset!important;
}

.MuiCalendarOrClockPicker-root > div {
    width: 100%!important;
}

.MuiCalendarPicker-root {
    width: 100%!important;
}

.MuiPickersCalendarHeader-label {
    font-size: smaller;
}

.Toastify__toast-container--top-right {
    top: 70px;
    right: 4px;
}

.mr-auto {
    margin-right: auto!important;
}

.ml-auto {
    margin-left: auto!important;
}

.cursor-pointer:hover {
    cursor: pointer;
    opacity: 0.8;
}