
.buttons{
  
padding-top:20%;
justify-content: flex-end;
display: flex;
  
}


.glass-magnifier-detail > div {
    position: initial!important;
}

.glass-magnifier-detail > div > img {
    margin: auto;
    object-fit: contain;
}

.glass-magnifier-multi {
    display: flex;
}

.glass-magnifier-multi > div > img {
    object-fit: contain;
    height: 100%;
    width: 100%;
    margin: auto;
}
