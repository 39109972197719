.searchBar{
    margin: 3% 3%;
   
}
.search{
    margin-left: 20%;
    width: 60%;
}
.up{
    float: right;
    margin-left: auto;
}
