.filter {
  width: 100%;
}

.filter-clear {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.accordion {
  display: flex;
  text-align: left;
  margin-bottom: 10px;
}

.accordion-item {
  width: 100%;
}


.accordion-header:hover {
  background-color: #f1f1f1;
}
