
.login-container {
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
}

.form {
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px;
  border: 5px #ffc451;
  border-style: double;
  border-bottom-right-radius: 80px;
  border-top-left-radius: 80px;
  background-color: rgba(0, 0, 0, 0.8);
}


#text {
  width: 250px;
  height: 15px;
  margin-top: 10px;
  border: 5px #ffc451;
  margin: -2px;
  border-radius: 5px;
  background-color: white;
}

.submit {
  width: 200px;
  font-weight: bold;
  background-color: unset;
}

span {
  font-size: 12px;
  margin-top: 10px;
}

.NewAdmin {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

}